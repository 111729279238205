import { motion } from 'framer-motion';

const transition = {
  transition: {
    times: [0, 0.1, 0.4, 0.45, 0.8, 1],
    repeat: Infinity,
    duration: 5,
  },
};

const animation = {
  head: {
    ...transition,
    rotate: [0, 1, 1, 3, 3, 0, 0],
  },
  muzzle: {
    ...transition,
    x: [0, -9, -9, -9, -9, 0, 0],
    y: [0, -4, -4, 2, 2, 0, 0],
  },
  nose: {
    ...transition,
    x: [0, -10, -10, -11, -11, 0, 0],
    y: [0, -5, -5, 4, 4, 0, 0],
    rotate: [0, 1, 1, 2, 2, 0, 0],
  },
  mouth: {
    ...transition,
    x: [0, -10, -10, -11, -11, 0, 0],
    y: [0, -5, -5, 4, 4, 0, 0],
    rotate: [0, 1, 1, 6, 6, 0, 0],
  },
  earRight: {
    ...transition,
    x: [0, 6, 6, 4, 4, 0, 0],
    y: [0, 4, 4, -2, -2, 0, 0],
  },
  earLeft: {
    ...transition,
    x: [0, 2, 2, 4, 4, 0, 0],
    y: [0, 6, 6, 0, 0, 0, 0],
  },
  nameVisible: {
    opacity: 1,
  },
  nameHidden: {
    opacity: 0,
  },
};

type LogoProps = {
  animated?: boolean;
  className?: string;
};

const Logo = ({ animated, className }: LogoProps) => {
  return (
    <motion.svg
      className={className}
      viewBox="0 0 847 192"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <motion.path
        key="head"
        className="fill-primary-500"
        animate={animated && animation.head}
        d="M41.753,20.432C56.92,9.435 73.546,2.599 100.77,0.339C124.689,-1.646 139.912,5.367 154.243,13.806C160.703,17.632 173.587,27.229 184.764,39.687C196.877,53.795 211.575,82.488 211.575,82.488C196.004,145.122 178.978,164.041 120.445,191.989C64.529,181.497 18.309,144.428 0.367,95.664C0.367,95.664 8.556,71.135 15.492,56.277C23.906,38.592 30.113,29.47 41.753,20.432Z"
      />
      <motion.path
        key="ear-right"
        className="fill-primary-500"
        animate={animated && animation.earRight}
        transition={transition}
        d="M41.753,20.432C56.92,9.435 8.556,71.135 15.492,56.277C13.361,55.314 11.33,53.918 9.472,52.06C0.652,43.241 -4.131,24.137 4.688,15.318C13.507,6.499 32.611,11.283 41.43,20.102C41.539,20.211 41.647,20.321 41.753,20.432Z"
      />
      <motion.path
        key="ear-left"
        className="fill-primary-500"
        animate={animated && animation.earLeft}
        transition={transition}
        d="M184.764,39.687C140.992,83.459 116.858,51.191 154.243,13.806C154.612,13.365 155.006,12.933 155.428,12.511C163.236,4.703 180.15,0.468 187.958,8.276C195.412,15.73 191.891,31.481 184.764,39.687Z"
      />
      <motion.path
        key="muzzle"
        className="fill-white"
        animate={animated && animation.muzzle}
        transition={transition}
        d="M121.012,107.67C102.502,107.67 87.475,95.989 87.475,77.479C87.475,58.969 102.502,33.902 121.012,33.902C139.522,33.902 154.55,58.969 154.55,77.479C154.55,95.989 139.522,107.67 121.012,107.67Z"
      />
      <motion.path
        key="nose"
        className="fill-accent-500"
        animate={animated && animation.nose}
        transition={transition}
        d="M121.439,58.273C115.093,58.667 110.154,58.86 109.769,52.315C109.353,45.263 114.816,40.476 121.868,40.06C128.92,39.645 134.907,43.758 135.323,50.809C135.688,57.007 131.29,57.684 125.433,58.039L125.836,64.887C126.953,64.926 120.834,65.362 121.846,65.184L121.439,58.273Z"
      />
      <motion.path
        key="mouth"
        className="fill-accent-500"
        animate={animated && animation.mouth}
        transition={transition}
        d="M121.7,62.704C122.898,62.538 124.427,62.407 125.69,62.408C126.891,62.408 128.045,62.535 129.28,62.708C133.189,63.254 137.016,64.282 137.016,64.282C137.694,64.361 138.211,64.942 138.211,65.624C138.211,66.365 137.602,66.975 136.861,66.975C136.809,66.975 136.757,66.972 136.706,66.966L124.143,67.372C122.809,67.372 121.049,67.559 119.266,67.722C115.446,68.071 111.619,68.444 111.619,68.444C111.532,68.461 111.445,68.47 111.357,68.47C110.615,68.47 110.005,67.86 110.005,67.118C110.005,66.513 110.411,65.978 110.995,65.815C111.578,65.653 114.695,64.407 118.503,63.401C119.592,63.113 120.62,62.853 121.7,62.704Z"
      />
      <g id="text">
        <path
          d="M287.702,134.279C279.823,134.279 272.858,132.613 266.806,129.283C260.754,125.953 256.009,121.369 252.571,115.532C249.133,109.694 247.414,103.016 247.414,95.495C247.414,89.765 248.399,84.537 250.369,79.81C252.338,75.083 255.114,70.965 258.695,67.455C262.276,63.946 266.502,61.242 271.372,59.344C276.242,57.446 281.614,56.497 287.487,56.497C295.365,56.497 302.313,58.162 308.329,61.493C314.345,64.823 319.072,69.425 322.51,75.298C325.948,81.171 327.667,87.903 327.667,95.495C327.667,100.151 326.987,104.52 325.626,108.602C324.265,112.685 322.367,116.337 319.932,119.56L325.948,125.684L313.593,137.717L306.395,130.304C300.737,132.954 294.506,134.279 287.702,134.279ZM287.702,116.015C289.134,116.015 290.531,115.872 291.892,115.585L285.123,108.71L297.693,96.784L305.428,104.735C306.646,101.941 307.255,98.861 307.255,95.495C307.255,91.413 306.413,87.814 304.73,84.698C303.047,81.583 300.719,79.147 297.747,77.393C294.775,75.638 291.355,74.761 287.487,74.761C283.691,74.761 280.307,75.638 277.335,77.393C274.362,79.147 272.035,81.583 270.351,84.698C268.668,87.814 267.827,91.413 267.827,95.495C267.827,99.506 268.686,103.051 270.405,106.131C272.124,109.211 274.47,111.628 277.442,113.383C280.414,115.138 283.834,116.015 287.702,116.015Z"
          fill="currentColor"
        />
        <path
          d="M353.988,106.776C353.988,112.792 356.03,115.908 360.112,116.122C365.555,116.337 368.277,113.58 368.277,107.85L368.277,79.273L387.615,79.273L387.615,132.99L369.351,132.99L368.599,126.436C365.018,131.664 360.148,134.279 353.988,134.279C347.471,134.279 342.6,132.291 339.377,128.316C336.154,124.341 334.579,118.235 334.65,109.999L334.65,79.273L353.988,79.273L353.988,106.776Z"
          fill="currentColor"
        />
        <path
          d="M398.358,132.99L398.358,79.273L417.696,79.273L417.696,132.99L398.358,132.99ZM408.027,74.116C405.162,74.116 402.656,73.042 400.507,70.893C398.358,68.744 397.284,66.238 397.284,63.373C397.284,60.436 398.358,57.911 400.507,55.799C402.656,53.686 405.162,52.629 408.027,52.629C410.964,52.629 413.489,53.686 415.601,55.799C417.714,57.911 418.771,60.436 418.771,63.373C418.771,66.238 417.714,68.744 415.601,70.893C413.489,73.042 410.964,74.116 408.027,74.116Z"
          fill="currentColor"
        />
        <path
          d="M425.754,132.99L425.754,119.56L447.455,94.851L425.754,94.851L425.754,79.273L471.306,79.273L471.306,92.702L449.604,117.519L471.306,117.519L471.306,132.99L425.754,132.99Z"
          fill="currentColor"
        />
        <path
          d="M475.603,132.99L475.603,119.56L497.305,94.851L475.603,94.851L475.603,79.273L521.155,79.273L521.155,92.702L499.453,117.519L521.155,117.519L521.155,132.99L475.603,132.99Z"
          fill="currentColor"
        />
        <rect x="528.138" y="57.786" width="19.338" height="75.203" fill="currentColor" />
        <path
          d="M573.367,155.765C570.861,155.765 568.211,155.282 565.417,154.315C562.624,153.348 560.404,152.328 558.756,151.253L565.955,135.675C567.602,137.108 569.285,137.824 571.004,137.824C572.15,137.824 573.242,137.538 574.281,136.965C575.319,136.392 576.125,135.425 576.698,134.064L577.557,131.915L552.31,79.273L573.797,79.273L586.259,110.321L597.647,79.273L619.134,79.273L596.036,134.923C594.389,138.934 592.67,142.515 590.879,145.667C589.089,148.818 586.85,151.289 584.165,153.08C581.479,154.87 577.88,155.765 573.367,155.765Z"
          fill="currentColor"
        />
        <path
          d="M623.969,132.99L623.969,57.786L643.307,57.786L643.307,84.859C646.601,80.275 651.4,77.984 657.703,77.984C665.366,77.984 671.329,80.455 675.59,85.396C679.852,90.338 681.983,97.25 681.983,106.131C681.983,115.012 679.852,121.924 675.59,126.866C671.329,131.808 665.366,134.279 657.703,134.279C651.185,134.279 646.279,131.808 642.984,126.866L642.34,132.99L623.969,132.99ZM652.546,116.445C655.196,116.445 657.38,115.478 659.099,113.544C660.818,111.61 661.714,109.139 661.785,106.131C661.714,103.123 660.836,100.67 659.153,98.772C657.47,96.874 655.303,95.925 652.653,95.925C649.932,95.925 647.693,96.874 645.939,98.772C644.184,100.67 643.307,103.123 643.307,106.131C643.307,109.139 644.184,111.61 645.939,113.544C647.693,115.478 649.896,116.445 652.546,116.445Z"
          fill="currentColor"
        />
        <path
          d="M716.147,134.279C710.345,134.279 705.188,133.097 700.676,130.733C696.164,128.37 692.636,125.075 690.094,120.85C687.551,116.624 686.28,111.718 686.28,106.131C686.28,100.545 687.533,95.638 690.04,91.413C692.547,87.187 696.021,83.892 700.461,81.529C704.902,79.165 710.023,77.984 715.824,77.984C721.339,77.984 726.192,79.183 730.381,81.583C734.571,83.982 737.866,87.617 740.265,92.487C742.665,97.357 743.864,103.517 743.864,110.966L706.263,110.966C706.764,114.189 707.892,116.427 709.647,117.68C711.402,118.934 713.389,119.56 715.609,119.56C717.973,119.56 720.032,119.005 721.787,117.895C723.542,116.785 724.634,115.406 725.063,113.759L742.038,119.023C740.749,122.748 738.69,125.738 735.861,127.994C733.031,130.25 729.88,131.861 726.406,132.828C722.933,133.795 719.513,134.279 716.147,134.279ZM715.072,91.413C712.709,91.413 710.864,92.075 709.539,93.4C708.214,94.725 707.265,96.641 706.692,99.148L723.774,99.148C723.201,96.713 722.234,94.815 720.874,93.454C719.513,92.093 717.579,91.413 715.072,91.413Z"
          fill="currentColor"
        />
        <path
          d="M766.855,134.279C760.624,134.279 755.772,132.864 752.298,130.035C748.824,127.206 747.087,123.141 747.087,117.841C747.087,112.255 748.824,108.119 752.298,105.433C755.772,102.747 760.624,101.404 766.855,101.404L780.392,101.404C780.105,99.041 779.371,97.125 778.189,95.656C777.008,94.188 774.805,93.454 771.582,93.454C770.006,93.454 768.681,93.794 767.607,94.475C766.533,95.155 765.423,96.247 764.277,97.751L747.195,92.38C749.702,87.939 752.996,84.43 757.079,81.851C761.161,79.273 766.139,77.984 772.012,77.984C777.742,77.984 782.648,79.022 786.73,81.099C790.813,83.176 793.928,86.077 796.077,89.801C798.226,93.526 799.3,97.859 799.3,102.801L799.3,132.99L781.144,132.99L781.036,127.51C777.742,132.023 773.015,134.279 766.855,134.279ZM771.475,120.742C774.125,120.742 776.22,119.972 777.76,118.432C779.299,116.892 780.069,114.905 780.069,112.47L774.268,112.47C769.04,112.47 766.425,114.117 766.425,117.412C766.425,119.632 768.108,120.742 771.475,120.742Z"
          fill="currentColor"
        />
        <path
          d="M808.432,132.99L808.432,79.273L827.77,79.273L827.77,89.372C830.062,85.934 832.909,83.355 836.311,81.636C839.713,79.917 843.204,79.058 846.786,79.058L846.786,97.966C843.419,97.966 840.286,98.271 837.385,98.879C834.484,99.488 832.157,100.401 830.402,101.619C828.647,102.837 827.77,104.305 827.77,106.024L827.77,132.99L808.432,132.99Z"
          fill="currentColor"
        />
      </g>
    </motion.svg>
  );
};

export const LogoStacked = ({ animated, className }: LogoProps) => {
  return (
    <motion.svg
      className={className}
      viewBox="0 0 600 315"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <motion.path
        key="head"
        className="fill-primary-500"
        animate={animated && animation.head}
        d="M235.651,20.432C250.818,9.435 267.444,2.599 294.668,0.339C318.587,-1.646 333.81,5.367 348.141,13.806C354.601,17.632 367.485,27.229 378.662,39.687C390.775,53.795 405.473,82.488 405.473,82.488C389.902,145.122 372.876,164.041 314.343,191.989C258.427,181.497 212.207,144.428 194.265,95.664C194.265,95.664 202.454,71.135 209.39,56.277C217.804,38.592 224.011,29.47 235.651,20.432Z"
      />
      <motion.path
        key="ear-right"
        className="fill-primary-500"
        animate={animated && animation.earRight}
        transition={transition}
        d="M235.651,20.432C250.818,9.435 202.454,71.135 209.39,56.277C207.259,55.314 205.228,53.918 203.37,52.06C194.55,43.241 189.767,24.137 198.586,15.318C207.405,6.499 226.509,11.283 235.328,20.102C235.437,20.211 235.545,20.321 235.651,20.432Z"
      />
      <motion.path
        key="ear-left"
        className="fill-primary-500"
        animate={animated && animation.earLeft}
        transition={transition}
        d="M378.662,39.687C334.89,83.459 310.756,51.191 348.141,13.806C348.51,13.365 348.904,12.933 349.326,12.511C357.134,4.703 374.048,0.468 381.856,8.276C389.31,15.73 385.789,31.481 378.662,39.687Z"
      />
      <motion.path
        key="muzzle"
        className="fill-white"
        animate={animated && animation.muzzle}
        transition={transition}
        d="M314.911,107.67C296.401,107.67 281.373,95.989 281.373,77.479C281.373,58.969 296.401,33.902 314.911,33.902C333.421,33.902 348.448,58.969 348.448,77.479C348.448,95.989 333.421,107.67 314.911,107.67Z"
      />
      <motion.path
        key="nose"
        className="fill-accent-500"
        animate={animated && animation.nose}
        transition={transition}
        d="M315.337,58.273C308.991,58.667 304.052,58.86 303.667,52.315C303.251,45.263 308.714,40.476 315.766,40.06C322.818,39.645 328.805,43.758 329.221,50.809C329.586,57.007 325.188,57.684 319.331,58.039L319.734,64.887C320.851,64.926 314.732,65.362 315.744,65.184L315.337,58.273Z"
      />
      <motion.path
        key="mouth"
        className="fill-accent-500"
        animate={animated && animation.mouth}
        transition={transition}
        d="M315.598,62.704C316.796,62.538 318.325,62.407 319.588,62.408C320.789,62.408 321.943,62.535 323.178,62.708C327.087,63.254 330.914,64.282 330.914,64.282C331.592,64.361 332.11,64.942 332.11,65.624C332.11,66.365 331.5,66.975 330.759,66.975C330.707,66.975 330.655,66.972 330.604,66.966L318.041,67.372C316.707,67.372 314.947,67.559 313.164,67.722C309.344,68.071 305.517,68.444 305.517,68.444C305.431,68.461 305.343,68.47 305.255,68.47C304.513,68.47 303.903,67.86 303.903,67.118C303.903,66.513 304.31,65.978 304.893,65.815C305.476,65.653 308.593,64.407 312.401,63.401C313.49,63.113 314.518,62.853 315.598,62.704Z"
      />
      <g id="text">
        <path
          d="M40.288,293.095C32.409,293.095 25.444,291.429 19.392,288.099C13.34,284.769 8.595,280.185 5.157,274.348C1.719,268.51 0,261.832 0,254.311C0,248.581 0.985,243.353 2.954,238.626C4.924,233.899 7.699,229.781 11.281,226.271C14.862,222.762 19.087,220.058 23.958,218.16C28.828,216.262 34.2,215.313 40.073,215.313C47.951,215.313 54.898,216.978 60.915,220.309C66.931,223.639 71.658,228.241 75.096,234.114C78.534,239.987 80.253,246.719 80.253,254.311C80.253,258.967 79.572,263.336 78.212,267.418C76.851,271.501 74.953,275.153 72.518,278.376L78.534,284.5L66.179,296.533L58.981,289.12C53.323,291.77 47.092,293.095 40.288,293.095ZM40.288,274.831C41.72,274.831 43.117,274.688 44.477,274.401L37.709,267.526L50.279,255.6L58.014,263.551C59.232,260.757 59.84,257.677 59.84,254.311C59.84,250.229 58.999,246.63 57.316,243.514C55.633,240.399 53.305,237.963 50.333,236.209C47.36,234.454 43.94,233.577 40.073,233.577C36.277,233.577 32.893,234.454 29.92,236.209C26.948,237.963 24.62,240.399 22.937,243.514C21.254,246.63 20.412,250.229 20.412,254.311C20.412,258.322 21.272,261.867 22.991,264.947C24.71,268.027 27.055,270.444 30.028,272.199C33,273.954 36.42,274.831 40.288,274.831Z"
          fill="currentColor"
        />
        <path
          d="M106.574,265.592C106.574,271.608 108.615,274.724 112.698,274.938C118.141,275.153 120.863,272.396 120.863,266.666L120.863,238.089L140.201,238.089L140.201,291.806L121.937,291.806L121.185,285.252C117.604,290.481 112.734,293.095 106.574,293.095C100.056,293.095 95.186,291.107 91.963,287.132C88.74,283.157 87.164,277.051 87.236,268.815L87.236,238.089L106.574,238.089L106.574,265.592Z"
          fill="currentColor"
        />
        <path
          d="M150.944,291.806L150.944,238.089L170.282,238.089L170.282,291.806L150.944,291.806ZM160.613,232.932C157.748,232.932 155.241,231.858 153.093,229.709C150.944,227.56 149.87,225.054 149.87,222.189C149.87,219.252 150.944,216.727 153.093,214.615C155.241,212.502 157.748,211.445 160.613,211.445C163.55,211.445 166.074,212.502 168.187,214.615C170.3,216.727 171.356,219.252 171.356,222.189C171.356,225.054 170.3,227.56 168.187,229.709C166.074,231.858 163.55,232.932 160.613,232.932Z"
          fill="currentColor"
        />
        <path
          d="M178.34,291.806L178.34,278.376L200.041,253.667L178.34,253.667L178.34,238.089L223.891,238.089L223.891,251.518L202.19,276.335L223.891,276.335L223.891,291.806L178.34,291.806Z"
          fill="currentColor"
        />
        <path
          d="M228.189,291.806L228.189,278.376L249.89,253.667L228.189,253.667L228.189,238.089L273.74,238.089L273.74,251.518L252.039,276.335L273.74,276.335L273.74,291.806L228.189,291.806Z"
          fill="currentColor"
        />
        <rect x="280.724" y="216.602" width="19.338" height="75.203" fill="currentColor" />
        <path
          d="M325.953,314.581C323.446,314.581 320.796,314.098 318.003,313.131C315.21,312.164 312.989,311.144 311.342,310.069L318.54,294.491C320.188,295.924 321.871,296.64 323.59,296.64C324.736,296.64 325.828,296.354 326.866,295.781C327.905,295.208 328.711,294.241 329.284,292.88L330.143,290.731L304.896,238.089L326.383,238.089L338.845,269.137L350.233,238.089L371.72,238.089L348.622,293.739C346.974,297.75 345.255,301.331 343.465,304.483C341.674,307.634 339.436,310.105 336.75,311.896C334.064,313.686 330.465,314.581 325.953,314.581Z"
          fill="currentColor"
        />
        <path
          d="M376.554,291.806L376.554,216.602L395.892,216.602L395.892,243.675C399.187,239.091 403.986,236.8 410.288,236.8C417.952,236.8 423.915,239.271 428.176,244.212C432.438,249.154 434.568,256.066 434.568,264.947C434.568,273.828 432.438,280.74 428.176,285.682C423.915,290.624 417.952,293.095 410.288,293.095C403.771,293.095 398.865,290.624 395.57,285.682L394.925,291.806L376.554,291.806ZM405.132,275.261C407.782,275.261 409.966,274.294 411.685,272.36C413.404,270.426 414.299,267.955 414.371,264.947C414.299,261.939 413.422,259.486 411.739,257.588C410.056,255.69 407.889,254.741 405.239,254.741C402.517,254.741 400.279,255.69 398.524,257.588C396.77,259.486 395.892,261.939 395.892,264.947C395.892,267.955 396.77,270.426 398.524,272.36C400.279,274.294 402.482,275.261 405.132,275.261Z"
          fill="currentColor"
        />
        <path
          d="M468.732,293.095C462.931,293.095 457.774,291.913 453.262,289.549C448.75,287.186 445.222,283.891 442.68,279.666C440.137,275.44 438.866,270.534 438.866,264.947C438.866,259.361 440.119,254.454 442.626,250.229C445.133,246.003 448.606,242.708 453.047,240.345C457.487,237.981 462.608,236.8 468.41,236.8C473.925,236.8 478.777,237.999 482.967,240.399C487.157,242.798 490.452,246.433 492.851,251.303C495.25,256.173 496.45,262.333 496.45,269.782L458.848,269.782C459.35,273.005 460.478,275.243 462.232,276.496C463.987,277.75 465.975,278.376 468.195,278.376C470.559,278.376 472.618,277.821 474.372,276.711C476.127,275.601 477.219,274.222 477.649,272.575L494.624,277.839C493.334,281.564 491.275,284.554 488.446,286.81C485.617,289.066 482.466,290.677 478.992,291.644C475.518,292.611 472.098,293.095 468.732,293.095ZM467.658,250.229C465.294,250.229 463.45,250.891 462.125,252.216C460.8,253.541 459.851,255.457 459.278,257.964L476.36,257.964C475.787,255.529 474.82,253.631 473.459,252.27C472.098,250.909 470.165,250.229 467.658,250.229Z"
          fill="currentColor"
        />
        <path
          d="M519.441,293.095C513.21,293.095 508.357,291.68 504.884,288.851C501.41,286.022 499.673,281.957 499.673,276.657C499.673,271.071 501.41,266.935 504.884,264.249C508.357,261.563 513.21,260.22 519.441,260.22L532.977,260.22C532.691,257.857 531.957,255.941 530.775,254.472C529.593,253.004 527.391,252.27 524.168,252.27C522.592,252.27 521.267,252.61 520.193,253.291C519.118,253.971 518.008,255.063 516.862,256.567L499.78,251.196C502.287,246.755 505.582,243.246 509.664,240.667C513.747,238.089 518.725,236.8 524.598,236.8C530.327,236.8 535.233,237.838 539.316,239.915C543.398,241.992 546.514,244.893 548.663,248.617C550.811,252.342 551.886,256.675 551.886,261.617L551.886,291.806L533.729,291.806L533.622,286.326C530.327,290.839 525.6,293.095 519.441,293.095ZM524.06,279.558C526.71,279.558 528.805,278.788 530.345,277.248C531.885,275.708 532.655,273.721 532.655,271.286L526.854,271.286C521.625,271.286 519.011,272.933 519.011,276.228C519.011,278.448 520.694,279.558 524.06,279.558Z"
          fill="currentColor"
        />
        <path
          d="M561.018,291.806L561.018,238.089L580.356,238.089L580.356,248.188C582.647,244.75 585.494,242.171 588.896,240.452C592.299,238.733 595.79,237.874 599.371,237.874L599.371,256.782C596.005,256.782 592.872,257.087 589.971,257.695C587.07,258.304 584.742,259.217 582.988,260.435C581.233,261.653 580.356,263.121 580.356,264.84L580.356,291.806L561.018,291.806Z"
          fill="currentColor"
        />
      </g>
    </motion.svg>
  );
};

export default Logo;
